import React from 'react';
import PropTypes from 'prop-types';

function NavButtons(props){
    return(
        <div style={props.navStyle} className="nav">
        <span style={props.backStyle}><button onClick={props.navButtonClick} name="back" className="button">{"<"} Back</button></span> 
        <span style={props.nextStyle}><button onClick={props.navButtonClick} name="next" className="button">Next {">"}</button></span>
        <span style={props.finishStyle}><button onClick={props.navButtonClick} name="finish" className="button">Finish</button></span>
        </div>
    )
}

NavButtons.prototype = {
    counter: PropTypes.number.isRequired,
    navButtonClick: PropTypes.func.isRequired
};

export default NavButtons;