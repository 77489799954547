import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransitionGroup} from 'react-transition-group';
import Modal from './Modal';

function Result(props){
    //if bookmarks is empty, then hide the test bookmark button
    const bmButtonStyle = props.bookmarks.length !== 0  ?  {}: {display:'none'};
    return(
        <CSSTransitionGroup
            className="container result"
            component="div"
            transitionName="fade"
            transitionEnterTimeout={800}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}
        >
            <div className="resultCenter">
                <h2>Your score is: <strong>{props.quizResult}</strong></h2>
            </div>
            <div className="resultStats">
                <div>Correct Answers:</div><div className="push">{props.quizScore}</div>
            </div>
            <div className="resultStats">
                <div>Incorrect Answers:</div><div className="push">{props.questionTotal-props.quizScore}</div>
            </div>
            <div className="resultStats">
                <div>Questions Answered:</div><div className="push">{props.questionTotal}</div>
            </div>
            <div className="resultStats">
                <div>Questions Bookmarked*:</div><div className="push">{props.bookmarks.length}</div>
            </div>
            <div className="resultStats">
                <div>State Dependent Questions**:</div><div className="push">4</div>
            </div>
            
            <div className="resultCenter">
                <div>Do you want to retake?</div>
            </div>
            <div className="resultCenter">
                <div><button className="button" onClick={props.restartTestClick}>All the questions</button>
                </div><div><span style={bmButtonStyle}><button className="button" onClick={()=>props.testIncorrectClick(props.bookmarks)}>Bookmarked questions</button></span></div>
            </div>
            <Modal show={props.showModal}
                onClose={props.closeModalClick}>
                The previous test result was loaded. Press "All the questions" or "Bookmarked questions" button to start again.
            </Modal>
            <div><h6>*Incorrectly answered questions are automatically bookmarked for retake.<br />
                    **Who is one of your state's U.S. Senators now? <a href="https://www.senate.gov/general/contact_information/senators_cfm.cfm" target="_blank" rel="noopener noreferrer">Click to find your senator</a><br />
                    **Name your U.S. Representative. <a href="https://ziplook.house.gov/htbin/findrep_house?ZIP=" target="_blank" rel="noopener noreferrer">Click to find your respresentative</a><br />
                    **Who is the Governor of your state now? <a href="https://en.wikipedia.org/wiki/List_of_capitals_in_the_United_States#State_capitals" target="_blank" rel="noopener noreferrer">Click to find your state capital</a><br />
                    **What is the capital of your state? <a href="https://en.wikipedia.org/wiki/List_of_United_States_governors#State_governors" target="_blank" rel="noopener noreferrer">Click to find your governor</a><br /></h6></div>
        </CSSTransitionGroup>
    )
}

Result.propTypes ={
    quizResult:PropTypes.string.isRequired
}
export default Result;