var quizquestion = [
    {
        id: 1,
        question: "What group of people was taken to America and sold as slaves?",
        answers: [
            {
                type: "a",
                content: "English"
            },
            {
                type: "b",
                content: "Dutch"
            },
            {
                type: "c",
                content: "Canadians"
            },
            {
                type: "d",
                content: "Africans"
            }
        ],
        answerKey: "d"
    }, {
        id: 2,
        question: "Where is the Statue of Liberty located?",
        answers: [
            {
                type: "a",
                content: "Boston Harbor"
            },
            {
                type: "b",
                content: "Long Island"
            },
            {
                type: "c",
                content: "San Francisco Bay"
            },
            {
                type: "d",
                content: "New York Harbor"
            }
        ],
        answerKey: "d"
    }, {
        id: 3,
        question: "If the president can no longer serve, who becomes the president?",
        answers: [
            {
                type: "a",
                content: "the Speaker of the House"
            },
            {
                type: "b",
                content: "the Vice President"
            },
            {
                type: "c",
                content: "the Secretary of State"
            },
            {
                type: "d",
                content: "the President Pro Tempore"
            }
        ],
        answerKey: "b"
    }, {
        id: 4,
        question: "Name one American Indian tribe in the United States.",
        answers: [
            {
                type: "a",
                content: "Cherokee"
            },
            {
                type: "b",
                content: "Zawi Chemi"
            },
            {
                type: "c",
                content: "Slavs"
            },
            {
                type: "d",
                content: "Celts"
            }
        ],
        answerKey: "a"
    }, {
        id: 5,
        question: "What is freedom of religion?",
        answers: [
            {
                type: "a",
                content: "You can practice any religion, or not practice a religion."
            },
            {
                type: "b",
                content: "You are required to choose a religion"
            },
            {
                type: "c",
                content: "No one can practice a religion"
            },
            {
                type: "d",
                content: "You cannot choose the time to practice your religion."
            }
        ],
        answerKey: "a"
    }, {
        id: 6,
        question: "How many amendments does the Constitution have?",
        answers: [
            {
                type: "a",
                content: "twenty three (23)"
            },
            {
                type: "b",
                content: "twenty one (21)"
            },
            {
                type: "c",
                content: "ten (10)"
            },
            {
                type: "d",
                content: "twenty seven (27)"
            }
        ],
        answerKey: "d"
    }, {
        id: 7,
        question: "Why does the U.S. flag have 13 stripes?",
        answers: [
            {
                type: "a",
                content: "because the stripes represent the members of the Second Continental Congress"
            },
            {
                type: "b",
                content: "because the stripes represent the number of signatures on the U.S. Constitution"
            },
            {
                type: "c",
                content: "because it was considered lucky to have 13 stripes on the flag"
            },
            {
                type: "d",
                content: "because the stripes represent the original colonies"
            }
        ],
        answerKey: "d"
    }, {
        id: 8,
        question: "How old do citizens have to be to vote for President?",
        answers: [
            {
                type: "a",
                content: "eighteen (18) and older"
            },
            {
                type: "b",
                content: "sixteen (16) and older"
            },
            {
                type: "c",
                content: "thirty five (35) and older"
            },
            {
                type: "d",
                content: "twenty one (21) and older"
            }
        ],
        answerKey: "a"
    }, {
        id: 9,
        question: "There were 13 original states. Name three of them.",
        answers: [
            {
                type: "a",
                content: "Virginia, North Carolina, and Florida"
            },
            {
                type: "b",
                content: "Washington, Oregon, and California"
            },
            {
                type: "c",
                content: "New York, Kentucky, and Georgia"
            },
            {
                type: "d",
                content: "Maryland, Virginia, and North Carolina"
            }
        ],
        answerKey: "d"
    }, {
        id: 10,
        question: "When must all men register for the Selective Service?",
        answers: [
            {
                type: "a",
                content: "at any age"
            },
            {
                type: "b",
                content: "at age sixteen (16)"
            },
            {
                type: "c",
                content: "between eighteen (18) and twenty six (26)"
            },
            {
                type: "d",
                content: "men do not have to register"
            }
        ],
        answerKey: "c"
    }, {
        id: 11,
        question: "What is the name of the Vice President of the United States now?",
        answers: [
            {
                type: "a",
                content: "Donald Trump"
            },
            {
                type: "b",
                content: "Mike Pence"
            },
            {
                type: "c",
                content: "Al Gore"
            },
            {
                type: "d",
                content: "Dick Cheney"
            }
        ],
        answerKey: "b"
    }, {
        id: 12,
        question: "Who is the Commander in Chief of the military?",
        answers: [
            {
                type: "a",
                content: "the Attorney General"
            },
            {
                type: "b",
                content: "the Secretary of Defense"
            },
            {
                type: "c",
                content: "the Vice President"
            },
            {
                type: "d",
                content: "the President"
            }
        ],
        answerKey: "d"
    }, {
        id: 13,
        question: "Who is the 'Father of Our Country'?",
        answers: [
            {
                type: "a",
                content: "Abraham Lincoln"
            },
            {
                type: "b",
                content: "George Washington"
            },
            {
                type: "c",
                content: "Patrick Henry"
            },
            {
                type: "d",
                content: "Thomas Jefferson"
            }
        ],
        answerKey: "b"
    }, {
        id: 14,
        question: "Name one state that borders Canada",
        answers: [
            {
                type: "a",
                content: "Oregon"
            },
            {
                type: "b",
                content: "South Dakota"
            },
            {
                type: "c",
                content: "Maine"
            },
            {
                type: "d",
                content: "Rhode Island"
            }
        ],
        answerKey: "c"
    }, {
        id: 15,
        question: "What is the economic system in the United States?",
        answers: [
            {
                type: "a",
                content: "communist economy"
            },
            {
                type: "b",
                content: "socialist economy"
            },
            {
                type: "c",
                content: "capitalist economy"
            },
            {
                type: "d",
                content: "none of the above answers"
            }
        ],
        answerKey: "c"
    }, {
        id: 16,
        question: "What are two Cabinet level positions?",
        answers: [
            {
                type: "a",
                content: "Secretary of the Interior and Secretary of History"
            },
            {
                type: "b",
                content: "Secretary of Weather and Secretary of Energy"
            },
            {
                type: "c",
                content: "Secretary of Health and Human Services and Secretary of the Navy"
            },
            {
                type: "d",
                content: "Secretary of Homeland Security and Secretary of the Treasury"
            }
        ],
        answerKey: "d"
    }, {
        id: 17,
        question: "We elect a U.S. Senator for how many years?",
        answers: [
            {
                type: "a",
                content: "two (2)"
            },
            {
                type: "b",
                content: "six (6)"
            },
            {
                type: "c",
                content: "four (4)"
            },
            {
                type: "d",
                content: "ten (10)"
            }
        ],
        answerKey: "b"
    }, {
        id: 18,
        question: "What are two rights of everyone living in the United States?",
        answers: [
            {
                type: "a",
                content: "freedom of worship and freedom to make treaties with other countries"
            },
            {
                type: "b",
                content: "freedom to petition the government and freedom to disobey traffic laws"
            },
            {
                type: "c",
                content: "freedom of speech and freedom to run for president"
            },
            {
                type: "d",
                content: "freedom of speech and freedom of worship"
            }
        ],
        answerKey: "d"
    }, {
        id: 19,
        question: "What happened at the Constitutional Convention?",
        answers: [
            {
                type: "a",
                content: "The Constitution was written"
            },
            {
                type: "b",
                content: "The Declaration of Independence was written"
            },
            {
                type: "c",
                content: "The Emancipation Proclamation was written"
            },
            {
                type: "d",
                content: "The Virginia Declaration of Rights was written"
            }
        ],
        answerKey: "a"
    }, {
        id: 20,
        question: "What is the political party of the President now?",
        answers: [
            {
                type: "a",
                content: "Democratic Party"
            },
            {
                type: "b",
                content: "Independent Party"
            },
            {
                type: "c",
                content: "Republican Party"
            },
            {
                type: "d",
                content: "Green Party"
            }
        ],
        answerKey: "c"
    }, {
        id: 21,
        question: "When was the Declaration of Independence adopted?",
        answers: [
            {
                type: "a",
                content: "March 4th, 1789"
            },
            {
                type: "b",
                content: "July 4th, 1789"
            },
            {
                type: "c",
                content: "July 4th, 1776"
            },
            {
                type: "d",
                content: "December 7th, 1787"
            }
        ],
        answerKey: "c"
    }, {
        id: 22,
        question: "During the Cold War, what was the main concern of the United States?",
        answers: [
            {
                type: "a",
                content: "Communism"
            },
            {
                type: "b",
                content: "climate change"
            },
            {
                type: "c",
                content: "slavery"
            },
            {
                type: "d",
                content: "the Great Depression"
            }
        ],
        answerKey: "a"
    }, {
        id: 23,
        question: "There are four amendments to the Constitution about who can vote. Describe one of them.",
        answers: [
            {
                type: "a",
                content: "Citizens who are eighteen (18) years and older can vote"
            },
            {
                type: "b",
                content: "Only citizens with a job can vote"
            },
            {
                type: "c",
                content: "Citizens who are seventeen (17) years and older can vote"
            },
            {
                type: "d",
                content: "Citizens by birth only can vote"
            }
        ],
        answerKey: "a"
    }, {
        id: 24,
        question: "What is one promise you make when you become a U.S. citizen?",
        answers: [
            {
                type: "a",
                content: "not defend the Constitution and laws of the United States"
            },
            {
                type: "b",
                content: "never travel outside of the United States"
            },
            {
                type: "c",
                content: "give up loyalty to other countries"
            },
            {
                type: "d",
                content: "disobey the laws of the United States"
            }
        ],
        answerKey: "c"
    }, {
        id: 25,
        question: "When is the last day you can send in federal income tax forms?",
        answers: [
            {
                type: "a",
                content: "July 4th"
            },
            {
                type: "b",
                content: "April 15th"
            },
            {
                type: "c",
                content: "March 15th"
            },
            {
                type: "d",
                content: "May 15th"
            }
        ],
        answerKey: "b"
    }, {
        id: 26,
        question: "What ocean is on the West Coast of the United States",
        answers: [
            {
                type: "a",
                content: "Pacific Ocean"
            },
            {
                type: "b",
                content: "Atlantic Ocean"
            },
            {
                type: "c",
                content: "Southern Ocean"
            },
            {
                type: "d",
                content: "Arctic Ocean"
            }
        ],
        answerKey: "a"
    }, {
        id: 27,
        question: "What territory did the United States buy from France in 1803?",
        answers: [
            {
                type: "a",
                content: "Quebec"
            },
            {
                type: "b",
                content: "Alaska"
            },
            {
                type: "c",
                content: "the Louisiana Territory"
            },
            {
                type: "d",
                content: "Haiti"
            }
        ],
        answerKey: "c"
    }, {
        id: 28,
        question: "What do we show loyalty to when we say the Pledge of Allegiance?",
        answers: [
            {
                type: "a",
                content: "the state where you live"
            },
            {
                type: "b",
                content: "Congress"
            },
            {
                type: "c",
                content: "the United States"
            },
            {
                type: "d",
                content: "the President"
            }
        ],
        answerKey: "c"
    }, {
        id: 29,
        question: "If both the President and the Vice President can no longer serve, who becomes President?",
        answers: [
            {
                type: "a",
                content: "the Speaker of the House"
            },
            {
                type: "b",
                content: "the President Pro Tempore"
            },
            {
                type: "c",
                content: "the Secretary of State"
            },
            {
                type: "d",
                content: "the Secretary of the Treasury"
            }
        ],
        answerKey: "a"
    }, {
        id: 30,
        question: "What does the President's Cabinet do?",
        answers: [
            {
                type: "a",
                content: "makes law"
            },
            {
                type: "b",
                content: "advises the President"
            },
            {
                type: "c",
                content: "commands the U.S. Armed Forces"
            },
            {
                type: "d",
                content: "all of these answers"
            }
        ],
        answerKey: "b"
    }, {
        id: 31,
        question: "What is the supreme law of the land?",
        answers: [
            {
                type: "a",
                content: "the Declaration of Independence"
            },
            {
                type: "b",
                content: "the Constitution"
            },
            {
                type: "c",
                content: "the Articles of Confederation"
            },
            {
                type: "d",
                content: "the Emancipation Proclamation"
            }
        ],
        answerKey: "b"
    }, {
        id: 32,
        question: "What did Susan B. Anthony do?",
        answers: [
            {
                type: "a",
                content: "fought for women's rights"
            },
            {
                type: "b",
                content: "make the first flag of the United States"
            },
            {
                type: "c",
                content: "founded the Red Cross"
            },
            {
                type: "d",
                content: "the first woman elected to the House of Representatives"
            }
        ],
        answerKey: "a"
    }, {
        id: 33,
        question: "What ocean is on the East Coast of the United States?",
        answers: [
            {
                type: "a",
                content: "Pacific Ocean"
            },
            {
                type: "b",
                content: "Indian Ocean"
            },
            {
                type: "c",
                content: "Atlantic Ocean"
            },
            {
                type: "d",
                content: "Arctic Ocean"
            }
        ],
        answerKey: "c"
    }, {
        id: 34,
        question: "When was the Constitution written?",
        answers: [
            {
                type: "a",
                content: "1776"
            },
            {
                type: "b",
                content: "1790"
            },
            {
                type: "c",
                content: "1787"
            },
            {
                type: "d",
                content: "1789"
            }
        ],
        answerKey: "c"
    }, {
        id: 35,
        question: "When do we celebrate Independence Day?",
        answers: [
            {
                type: "a",
                content: "March 4th"
            },
            {
                type: "b",
                content: "January 1st"
            },
            {
                type: "c",
                content: "July 4th"
            },
            {
                type: "d",
                content: "June 30th"
            }
        ],
        answerKey: "c"
    }, {
        id: 36,
        question: "What is an amendment?",
        answers: [
            {
                type: "a",
                content: "an addition (to the Constitution)"
            },
            {
                type: "b",
                content: "an introduction"
            },
            {
                type: "c",
                content: "the beginning of the Declaration of Independence"
            },
            {
                type: "d",
                content: "the Preamble to the Constitution"
            }
        ],
        answerKey: "a"
    }, {
        id: 37,
        question: "Name one branch or part of the U.S. government",
        answers: [
            {
                type: "a",
                content: "Legislative"
            },
            {
                type: "b",
                content: "United Nations"
            },
            {
                type: "c",
                content: "parliament"
            },
            {
                type: "d",
                content: "state government"
            }
        ],
        answerKey: "a"
    }, {
        id: 38,
        question: "What are the two major political parties in the United States?",
        answers: [
            {
                type: "a",
                content: "American and Bull-Moose"
            },
            {
                type: "b",
                content: "Democratic and Republican"
            },
            {
                type: "c",
                content: "Reform and Green"
            },
            {
                type: "d",
                content: "Democratic-Republican and Whigs"
            }
        ],
        answerKey: "b"
    }, {
        id: 39,
        question: "What is the name of the President of the United States now?",
        answers: [
            {
                type: "a",
                content: "Donald Trump"
            },
            {
                type: "b",
                content: "Bill Clinton"
            },
            {
                type: "c",
                content: "George Bush"
            },
            {
                type: "d",
                content: "Joe Biden"
            }
        ],
        answerKey: "a"
    }, {
        id: 40,
        question: "Who was the first President?",
        answers: [
            {
                type: "a",
                content: "John Adams"
            },
            {
                type: "b",
                content: "Abraham Lincoln"
            },
            {
                type: "c",
                content: "Thomas Jefferson"
            },
            {
                type: "d",
                content: "George Washington"
            }
        ],
        answerKey: "d"
    }, {
        id: 41,
        question: "Why do some states have more Representatives than other states?",
        answers: [
            {
                type: "a",
                content: "because the state's Representatives have seniority in the House of Representatives"
            },
            {
                type: "b",
                content: "because of the state's location"
            },
            {
                type: "c",
                content: "because of the geographical size of the state"
            },
            {
                type: "d",
                content: "because of the state's population"
            }
        ],
        answerKey: "d"
    }, {
        id: 42,
        question: "What movement tried to end racial discrimination?",
        answers: [
            {
                type: "a",
                content: "prohibition"
            },
            {
                type: "b",
                content: "women's suffrage"
            },
            {
                type: "c",
                content: "conservation"
            },
            {
                type: "d",
                content: "civil rights movement"
            }
        ],
        answerKey: "d"
    }, {
        id: 43,
        question: "What is one reason colonists came to America?",
        answers: [
            {
                type: "a",
                content: "none of the answers"
            },
            {
                type: "b",
                content: "for the experience traveling across the ocean"
            },
            {
                type: "c",
                content: "to join a civic group"
            },
            {
                type: "d",
                content: "freedom"
            }
        ],
        answerKey: "d"
    }, {
        id: 44,
        question: "Under our Constitution, some powers belong to the states. What is one power of the states?",
        answers: [
            {
                type: "a",
                content: "provide schooling and education"
            },
            {
                type: "b",
                content: "make treaties"
            },
            {
                type: "c",
                content: "create an army"
            },
            {
                type: "d",
                content: "coin or print money"
            }
        ],
        answerKey: "a"
    }, {
        id: 45,
        question: "What did the Declaration of Independence do?",
        answers: [
            {
                type: "a",
                content: "freed the slaves"
            },
            {
                type: "b",
                content: "declared our independence from France"
            },
            {
                type: "c",
                content: "declared our independence from Great Britain"
            },
            {
                type: "d",
                content: "gave women the right to vote"
            }
        ],
        answerKey: "c"
    }, {
        id: 46,
        question: "Under our Constitution, some powers belong to the federal government. What is one power of the federal government?",
        answers: [
            {
                type: "a",
                content: "to issue driver's licenses"
            },
            {
                type: "b",
                content: "to make treaties"
            },
            {
                type: "c",
                content: "to provide schooling"
            },
            {
                type: "d",
                content: "to provide police departments"
            }
        ],
        answerKey: "b"
    }, {
        id: 47,
        question: "What is the name of the Speaker of the House of Representatives now?",
        answers: [
            {
                type: "a",
                content: "Nancy Pelosi"
            },
            {
                type: "b",
                content: "Mike Pence"
            },
            {
                type: "c",
                content: "Hillary Clinton"
            },
            {
                type: "d",
                content: "Joe Biden"
            }
        ],
        answerKey: "a"
    }, {
        id: 48,
        question: "Who vetoes bills?",
        answers: [
            {
                type: "a",
                content: "the Speaker of the House"
            },
            {
                type: "b",
                content: "the President"
            },
            {
                type: "c",
                content: "the Vice President"
            },
            {
                type: "d",
                content: "the President Pro Tempore"
            }
        ],
        answerKey: "b"
    }, {
        id: 49,
        question: "Who signs bills to become laws?",
        answers: [
            {
                type: "a",
                content: "the Secretary of State"
            },
            {
                type: "b",
                content: "the President"
            },
            {
                type: "c",
                content: "the Chief Justice of the Supreme Court"
            },
            {
                type: "d",
                content: "the Vice President"
            }
        ],
        answerKey: "b"
    }, {
        id: 50,
        question: "Name one of the two longest rivers in the United States?",
        answers: [
            {
                type: "a",
                content: "Colorado River"
            },
            {
                type: "b",
                content: "Rio Grande River"
            },
            {
                type: "c",
                content: "Ohio River"
            },
            {
                type: "d",
                content: "Mississippi River"
            }
        ],
        answerKey: "d"
    }, {
        id: 51,
        question: "What is the 'rule of law'?",
        answers: [
            {
                type: "a",
                content: "Government does not have to follow the law."
            },
            {
                type: "b",
                content: "Everyone must follow the law."
            },
            {
                type: "c",
                content: "All laws must be the same in every state."
            },
            {
                type: "d",
                content: "Everyone but the President must follow the law."
            }
        ],
        answerKey: "b"
    }, {
        id: 52,
        question: "In what month do we vote for President?",
        answers: [
            {
                type: "a",
                content: "February"
            },
            {
                type: "b",
                content: "January"
            },
            {
                type: "c",
                content: "October"
            },
            {
                type: "d",
                content: "November"
            }
        ],
        answerKey: "d"
    }, {
        id: 53,
        question: "What are two ways that Americans can participate in their democracy?",
        answers: [
            {
                type: "a",
                content: "all of these answers"
            },
            {
                type: "b",
                content: "given an elected official your opinion on an issue and join a community group"
            },
            {
                type: "c",
                content: "vote and join a civic group"
            },
            {
                type: "d",
                content: "write to a newspaper and call Senators and Representatives"
            }
        ],
        answerKey: "a"
    }, {
        id: 54,
        question: "What did the Emancipation Proclamation do?",
        answers: [
            {
                type: "a",
                content: "freed slaves in most Southern states"
            },
            {
                type: "b",
                content: "ended World War I"
            },
            {
                type: "c",
                content: "gave women the right to vote"
            },
            {
                type: "d",
                content: "gave the United States independence from Great Britain"
            }
        ],
        answerKey: "a"
    }, {
        id: 55,
        question: "Who is in charge of the Executive branch?",
        answers: [
            {
                type: "a",
                content: "the Speaker of the House"
            },
            {
                type: "b",
                content: "the President"
            },
            {
                type: "c",
                content: "the Prime Minister"
            },
            {
                type: "d",
                content: "the Chief Justice"
            }
        ],
        answerKey: "b"
    }, {
        id: 56,
        question: "What major event happened on September 11, 2001, in the United States?",
        answers: [
            {
                type: "a",
                content: "The Japanese attacked Pearl Harbor."
            },
            {
                type: "b",
                content: "The accident at Three Mile Island Nuclear Power Plant occurred."
            },
            {
                type: "c",
                content: "Terrorists attacked the United States."
            },
            {
                type: "d",
                content: "Hurricane Andrew struck the United States."
            }
        ],
        answerKey: "c"
    }, {
        id: 57,
        question: "What are two rights in the Declaration of Independence?",
        answers: [
            {
                type: "a",
                content: "life and death"
            },
            {
                type: "b",
                content: "life and pursuit of happiness"
            },
            {
                type: "c",
                content: "liberty and justice"
            },
            {
                type: "d",
                content: "life and right to own a home"
            }
        ],
        answerKey: "b"
    }, {
        id: 58,
        question: "What is the highest court in the United States?",
        answers: [
            {
                type: "a",
                content: "the Supreme Court"
            },
            {
                type: "b",
                content: "the District Court"
            },
            {
                type: "c",
                content: "the Federal Court"
            },
            {
                type: "d",
                content: "the Court of Appeals"
            }
        ],
        answerKey: "a"
    }, {
        id: 59,
        question: "What is the capital of the United States?",
        answers: [
            {
                type: "a",
                content: "Washington, D.C."
            },
            {
                type: "b",
                content: "Philadelphia, PA"
            },
            {
                type: "c",
                content: "Boston, MA"
            },
            {
                type: "d",
                content: "New York, NY"
            }
        ],
        answerKey: "a"
    }, {
        id: 60,
        question: "Who was President during World War I?",
        answers: [
            {
                type: "a",
                content: "Franklin Roosevelt"
            },
            {
                type: "b",
                content: "Woodrow Wilson"
            },
            {
                type: "c",
                content: "Theodore Roosevelt"
            },
            {
                type: "d",
                content: "Warren Harding"
            }
        ],
        answerKey: "b"
    }, {
        id: 61,
        question: "How many justices are on the Supreme Court?",
        answers: [
            {
                type: "a",
                content: "nine (9)"
            },
            {
                type: "b",
                content: "twelve (12)"
            },
            {
                type: "c",
                content: "elevent (11)"
            },
            {
                type: "d",
                content: "ten (10)"
            }
        ],
        answerKey: "a"
    }, {
        id: 62,
        question: "We elect a President for how many years?",
        answers: [
            {
                type: "a",
                content: "eight (8)"
            },
            {
                type: "b",
                content: "ten (10)"
            },
            {
                type: "c",
                content: "two (2)"
            },
            {
                type: "d",
                content: "four (4)"
            }
        ],
        answerKey: "d"
    }, {
        id: 63,
        question: "What is one thing Benjamin Franklin is famous for?",
        answers: [
            {
                type: "a",
                content: "youngest member of the Constitutional Convention"
            },
            {
                type: "b",
                content: "third President of the United States"
            },
            {
                type: "c",
                content: "U.S. diplomat"
            },
            {
                type: "d",
                content: "inventor of the airplane"
            }
        ],
        answerKey: "c"
    }, {
        id: 64,
        question: "Why does the flag have 50 stars?",
        answers: [
            {
                type: "a",
                content: "because there were 50 people who originally came to the United States"
            },
            {
                type: "b",
                content: "because there is one star for each state"
            },
            {
                type: "c",
                content: "because there is one star for each president"
            },
            {
                type: "d",
                content: "because there were 50 original colonies"
            }
        ],
        answerKey: "b"
    }, {
        id: 65,
        question: "What was one important thing that Abraham Lincoln did?",
        answers: [
            {
                type: "a",
                content: "saved (or preserved) the Union"
            },
            {
                type: "b",
                content: "purchased Alaska"
            },
            {
                type: "c",
                content: "established the United Nations"
            },
            {
                type: "d",
                content: "declared war on Great Britain"
            }
        ],
        answerKey: "a"
    }, {
        id: 66,
        question: "Name one state that borders Mexico.",
        answers: [
            {
                type: "a",
                content: "Arkansas"
            },
            {
                type: "b",
                content: "Florida"
            },
            {
                type: "c",
                content: "California"
            },
            {
                type: "d",
                content: "Alabama"
            }
        ],
        answerKey: "c"
    }, {
        id: 67,
        question: "What does the Constitution do?",
        answers: [
            {
                type: "a",
                content: "sets up the government"
            },
            {
                type: "b",
                content: "protects basic rights of Americans"
            },
            {
                type: "c",
                content: "defines the government"
            },
            {
                type: "d",
                content: "all of these answers"
            }
        ],
        answerKey: "d"
    }, {
        id: 68,
        question: "What do we call the first ten amendments to the Constitution?",
        answers: [
            {
                type: "a",
                content: "the Articles of Confederation"
            },
            {
                type: "b",
                content: "the Bill of Rights"
            },
            {
                type: "c",
                content: "the Declaration of Independence"
            },
            {
                type: "d",
                content: "the inalienable rights"
            }
        ],
        answerKey: "b"
    }, {
        id: 69,
        question: "Before he was President, Eisenhower was a general. What war was he in?",
        answers: [
            {
                type: "a",
                content: "World War II"
            },
            {
                type: "b",
                content: "Vietnam War"
            },
            {
                type: "c",
                content: "Spanish-American War"
            },
            {
                type: "d",
                content: "Civil War"
            }
        ],
        answerKey: "a"
    }, {
        id: 70,
        question: "What is one right of freedom from the First Amendment?",
        answers: [
            {
                type: "a",
                content: "to bear arms"
            },
            {
                type: "b",
                content: "to vote"
            },
            {
                type: "c",
                content: "trial by jury"
            },
            {
                type: "d",
                content: "freedom of speech"
            }
        ],
        answerKey: "d"
    }, {
        id: 71,
        question: "What does the judicial branch do?",
        answers: [
            {
                type: "a",
                content: "reviews laws"
            },
            {
                type: "b",
                content: "all of these answers"
            },
            {
                type: "c",
                content: "resolves disputes"
            },
            {
                type: "d",
                content: "decides if a law goes against the Constitution"
            }
        ],
        answerKey: "b"
    }, {
        id: 72,
        question: "The House of Representatives has how many voting members?",
        answers: [
            {
                type: "a",
                content: "one hundred (100)"
            },
            {
                type: "b",
                content: "four hundred thirty-five (435)"
            },
            {
                type: "c",
                content: "four hundred forty-one (441)"
            },
            {
                type: "d",
                content: "two hundred (200)"
            }
        ],
        answerKey: "b"
    }, {
        id: 73,
        question: "Who did the United States fight in World War II?",
        answers: [
            {
                type: "a",
                content: "the Soviet Union, Germany, and Italy"
            },
            {
                type: "b",
                content: "Austria-Hungary, Japan, and Germany"
            },
            {
                type: "c",
                content: "Japan, China, and Vietnam"
            },
            {
                type: "d",
                content: "Japan, Germany, and Italy"
            }
        ],
        answerKey: "d"
    }, {
        id: 74,
        question: "Who does a U.S. Senator represent?",
        answers: [
            {
                type: "a",
                content: "the state legislatures"
            },
            {
                type: "b",
                content: "only the people in the state who voted for the Senator"
            },
            {
                type: "c",
                content: "all people of the state who belong to the Senator's political party"
            },
            {
                type: "d",
                content: "all people of the state"
            }
        ],
        answerKey: "d"
    }, {
        id: 75,
        question: "Who lived in America before the Europeans arrived?",
        answers: [
            {
                type: "a",
                content: "no one"
            },
            {
                type: "b",
                content: "Canadians"
            },
            {
                type: "c",
                content: "American Indians"
            },
            {
                type: "d",
                content: "Floridians"
            }
        ],
        answerKey: "c"
    }, {
        id: 76,
        question: "What is one responsibility that is only for United States citizens?",
        answers: [
            {
                type: "a",
                content: "pay taxes"
            },
            {
                type: "b",
                content: "serve on a jury"
            },
            {
                type: "c",
                content: "be respectful of others"
            },
            {
                type: "d",
                content: "obey the law"
            }
        ],
        answerKey: "b"
    }, {
        id: 77,
        question: "Who is the Chief Justice of the United States now?",
        answers: [
            {
                type: "a",
                content: "Joe Biden"
            },
            {
                type: "b",
                content: "Barack Obama"
            },
            {
                type: "c",
                content: "Anthony Kennedy"
            },
            {
                type: "d",
                content: "John G. Roberts, Jr."
            }
        ],
        answerKey: "d"
    }, {
        id: 78,
        question: "Who wrote the Declaration of Independence?",
        answers: [
            {
                type: "a",
                content: "James Madison"
            },
            {
                type: "b",
                content: "George Washington"
            },
            {
                type: "c",
                content: "Thomas Jefferson"
            },
            {
                type: "d",
                content: "Abraham Lincoln"
            }
        ],
        answerKey: "c"
    }, {
        id: 79,
        question: "Name one war fought by the United States in the 1900s.",
        answers: [
            {
                type: "a",
                content: "World War I"
            },
            {
                type: "b",
                content: "Revolutionary War"
            },
            {
                type: "c",
                content: "Civil War"
            },
            {
                type: "d",
                content: "War of 1812"
            }
        ],
        answerKey: "a"
    }, {
        id: 80,
        question: "What stops one branch of government from becoming too powerful?",
        answers: [
            {
                type: "a",
                content: "checks and balances"
            },
            {
                type: "b",
                content: "the people"
            },
            {
                type: "c",
                content: "the President"
            },
            {
                type: "d",
                content: "freedom of speech"
            }
        ],
        answerKey: "a"
    }, {
        id: 81,
        question: "Name one U.S. territory.",
        answers: [
            {
                type: "a",
                content: "Guam"
            },
            {
                type: "b",
                content: "Bermuda"
            },
            {
                type: "c",
                content: "Haiti"
            },
            {
                type: "d",
                content: "Cayman Islands"
            }
        ],
        answerKey: "a"
    }, {
        id: 82,
        question: "What is the name of the national anthem?",
        answers: [
            {
                type: "a",
                content: "My Country Tis of Thee"
            },
            {
                type: "b",
                content: "The Star-Spangled Banner"
            },
            {
                type: "c",
                content: "America the Beautiful"
            },
            {
                type: "d",
                content: "God Bless the U.S.A."
            }
        ],
        answerKey: "b"
    }, {
        id: 83,
        question: "Name two national U.S. holidays.",
        answers: [
            {
                type: "a",
                content: "Citizenship Day and Columbus Day"
            },
            {
                type: "b",
                content: "Valentine's Day and President's Day"
            },
            {
                type: "c",
                content: "Labor Day and Thanksgiving"
            },
            {
                type: "d",
                content: "April Fool's Day and Labor Day"
            }
        ],
        answerKey: "c"
    }, {
        id: 84,
        question: "What did Martin Luther King, Jr. do?",
        answers: [
            {
                type: "a",
                content: "ran for President of the United States"
            },
            {
                type: "b",
                content: "fought for civil rights"
            },
            {
                type: "c",
                content: "fought for women's suffrage"
            },
            {
                type: "d",
                content: "became a U.S. Senator"
            }
        ],
        answerKey: "b"
    }, {
        id: 85,
        question: "Who makes federal laws?",
        answers: [
            {
                type: "a",
                content: "the President"
            },
            {
                type: "b",
                content: "the states"
            },
            {
                type: "c",
                content: "the Supreme Court"
            },
            {
                type: "d",
                content: "Congress"
            }
        ],
        answerKey: "d"
    }, {
        id: 86,
        question: "Name one problem that led to the Civil War.",
        answers: [
            {
                type: "a",
                content: "sugar"
            },
            {
                type: "b",
                content: "oil"
            },
            {
                type: "c",
                content: "westward expansion"
            },
            {
                type: "d",
                content: "slavery"
            }
        ],
        answerKey: "d"
    }, {
        id: 87,
        question: "We elect a U.S. Representative for how many years?",
        answers: [
            {
                type: "a",
                content: "eight (8)"
            },
            {
                type: "b",
                content: "six (6)"
            },
            {
                type: "c",
                content: "two (2)"
            },
            {
                type: "d",
                content: "four (4)"
            }
        ],
        answerKey: "c"
    }, {
        id: 88,
        question: "How many U.S. Senators are there?",
        answers: [
            {
                type: "a",
                content: "one hundred (100)"
            },
            {
                type: "b",
                content: "fifty-two (52)"
            },
            {
                type: "c",
                content: "four hundred thirty-five (435)"
            },
            {
                type: "d",
                content: "fifty (50)"
            }
        ],
        answerKey: "a"
    }, {
        id: 89,
        question: "The Federalist Papers supported the passage of the U.S. Constitution. Name one of the writers.",
        answers: [
            {
                type: "a",
                content: "Thomas Jefferson"
            },
            {
                type: "b",
                content: "James Madison"
            },
            {
                type: "c",
                content: "George Washington"
            },
            {
                type: "d",
                content: "John Adams"
            }
        ],
        answerKey: "b"
    }, {
        id: 90,
        question: "Name one war fought by the United States in the 1800s.",
        answers: [
            {
                type: "a",
                content: "World War II"
            },
            {
                type: "b",
                content: "World War I"
            },
            {
                type: "c",
                content: "Korean War"
            },
            {
                type: "d",
                content: "Civil War"
            }
        ],
        answerKey: "d"
    }, {
        id: 91,
        question: "The idea of self-government is in the first three words of the Constitution. What are these words?",
        answers: [
            {
                type: "a",
                content: "We the British"
            },
            {
                type: "b",
                content: "We the Colonists"
            },
            {
                type: "c",
                content: "We the People"
            },
            {
                type: "d",
                content: "Congress shall make"
            }
        ],
        answerKey: "c"
    }, {
        id: 92,
        question: "What are the two parts of the U.S. Congress?",
        answers: [
            {
                type: "a",
                content: "the House of Representatives and the Courts"
            },
            {
                type: "b",
                content: "the Senate and the Courts"
            },
            {
                type: "c",
                content: "the Senate and House of Representatives"
            },
            {
                type: "d",
                content: "the House of Lords and the House of Commons"
            }
        ],
        answerKey: "c"
    }, {
        id: 93,
        question: "Name one right only for United States citizens",
        answers: [
            {
                type: "a",
                content: "run for federal office"
            },
            {
                type: "b",
                content: "freedom of religion"
            },
            {
                type: "c",
                content: "attend public school"
            },
            {
                type: "d",
                content: "freedom of speech"
            }
        ],
        answerKey: "a"
    }, {
        id: 94,
        question: "Who was President during the Great Depression and World War II?",
        answers: [
            {
                type: "a",
                content: "Herbert Hoover"
            },
            {
                type: "b",
                content: "Harry Truman"
            },
            {
                type: "c",
                content: "Franklin Roosevelt"
            },
            {
                type: "d",
                content: "Calvin Coolidge"
            }
        ],
        answerKey: "c"
    }, {
        id: 95,
        question: "Why did the colonists fight the British?",
        answers: [
            {
                type: "a",
                content: "all of these answers"
            },
            {
                type: "b",
                content: "because the British army stayed in their houses"
            },
            {
                type: "c",
                content: "because of high taxes"
            },
            {
                type: "d",
                content: "because they didn't have self-government"
            }
        ],
        answerKey: "a"
    }, {
        id: 96,
        question: "Name the U.S. war between the North and the South.",
        answers: [
            {
                type: "a",
                content: "the War of 1812"
            },
            {
                type: "b",
                content: "the Civil War"
            },
            {
                type: "c",
                content: "World War I"
            },
            {
                type: "d",
                content: "the Revolutionary War"
            }
        ],
        answerKey: "b"
    }
];

export default quizquestion;