import React from 'react';
import logo from './logo.svg';
import './App.css';
import quizQuestions from './api/quizQuestions';
import Quiz from './components/Quiz';
import Result from './components/Result';
import appleBadge from './svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import GoogleAd from './components/GoogleAd';

class App extends React.Component {
  constructor(props){
    super(props)
    //initialize state and some class variables
    this.state ={
      counter: 0,
      questionId: 1,
      question: '',
      answerOptions: [],
      chosenAnswer:[],
      answerKey:[],
      answersCount:[],
      bookmarks:[],
      bookmarkMode:false,
      questionsLength: 0,
      result:'',
      score:0,
      isOpen: false
    }
    //need to bind the method to the class to pass the method to other js files
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.navButtonClick = this.navButtonClick.bind(this);
    this.bookmarkClick = this.bookmarkClick.bind(this);
    this.testIncorrectClick = this.testIncorrectClick.bind(this);
    this.restartTestClick = this.restartTestClick.bind(this);
    this.closeModalClick = this.closeModalClick.bind(this);
    this.newBookmarkQuiz= "";
    this.nBMAnswerOptions="";
    this.hasStorage=false;
    this.myBookmarks = [];
    this.myScore=0;
    this.lengthStore=0;
  }

  componentDidMount(){
    //detect local storage support or not
    this.hasStorage = (function() {
      try {
        localStorage.setItem("mod", "mod");
        localStorage.removeItem("mod");
        return true;
      } catch (exception) {
        return false;
      }
    }());
    //randomize questions object variable from quizQuestions.js
    quizQuestions.sort(() => Math.random()-0.5);
    //randmize the answer options
    const shuffledAnswerOptions = quizQuestions.map((question)=>this.shuffleArray(question.answers))
    
    //if localstorage is supported abd quizQuestionsObject is not null.
    if(this.hasStorage && localStorage.getItem('quizQuestionsObj') !== null){

      if(this.isStorageValueSet("bookmarkStore")){
         //get localStorage bookmarkStore key and turn the values into JSON object of integers
        this.myBookmarks = JSON.parse(localStorage.getItem("bookmarkStore")).map((x)=>parseInt(x))
        //if bookmarkStore is set means scoreStore & lengthStore are also set because the setResult method (clicking the finish button) sets them all at once.
        this.myScore = parseInt(localStorage.getItem("scoreStore"))
        this.lengthStore = parseInt(localStorage.getItem("lengthStore"))
      }else{
        //the finish button has not been clicked or quiz is still not at the end. If user refresh browser, the data will be loaded.
        if(this.isStorageValueSet("stateBookmarks")){
          this.myBookmarks = JSON.parse(localStorage.getItem("stateBookmarks")).map((x)=>parseInt(x))
        }else{
          this.myBookmarks = []
        }
        //reload the score
        if(this.isStorageValueSet("stateScore")){
          this.myScore = parseInt(localStorage.getItem("stateScore"))
        }else{
          this.myScore = 0
        }
        //this reloads the question length to address question total if user refreshes in "bookmark mode"
        this.lengthStore = JSON.parse(localStorage.getItem("quizQuestionsObj")).length
      }
      this.setState({
        question: this.isStorageValueSet("stateCounter") ? JSON.parse(localStorage.getItem("quizQuestionsObj"))[parseInt(localStorage.getItem("stateCounter"))].question : JSON.parse(localStorage.getItem("quizQuestionsObj"))[0].question,
        questionId:this.isStorageValueSet("stateCounter") ? parseInt(JSON.parse(localStorage.getItem("quizQuestionsObj"))[parseInt(localStorage.getItem("stateCounter"))].id) : parseInt(JSON.parse(localStorage.getItem("quizQuestionsObj"))[0].id),
        answerOptions:this.isStorageValueSet("stateCounter") ? JSON.parse(localStorage.getItem("shuffledAnswerOptionsObj"))[parseInt(localStorage.getItem("stateCounter"))] : JSON.parse(localStorage.getItem("shuffledAnswerOptionsObj"))[0],
        answerKey:this.isStorageValueSet("stateCounter") ? JSON.parse(localStorage.getItem("quizQuestionsObj"))[parseInt(localStorage.getItem("stateCounter"))].answerKey: JSON.parse(localStorage.getItem("quizQuestionsObj"))[0].answerKey,
        questionsLength:this.lengthStore,
        counter: parseInt(localStorage.getItem("stateCounter")) || 0,
        chosenAnswer:JSON.parse(localStorage.getItem("stateChosenAnswer")) || [],
        bookmarks:this.myBookmarks,
        bookmarkMode:JSON.parse(localStorage.getItem("stateBookmarkMode")) || false,
        result:localStorage.getItem("stateResult") || '',
        score:this.myScore
      }, ()=>{
            //callback to store counter data to localStorage
            localStorage.setItem("stateCounter", this.state.counter)
            //when it's bookmark mode, send the bookmarks array to help filter the original questions into just bookmarked questions.
            // if(this.state.bookmarkMode){
            //   this.testIncorrectClick(this.state.bookmarks)
            // }
            
            //this is for opening the modal dialog message box.
            if(this.state.result !== ''){
              this.setState({
                isOpen:true
              })
            }
         })
    }else{
      //if localStorage not supported or quizQuestionsObj is null. Start fresh with data from the quizQuestions.js file
      this.setState({
        question: quizQuestions[0].question,
        questionId:quizQuestions[0].id,
        answerOptions:shuffledAnswerOptions[0],
        answerKey:quizQuestions[0].answerKey,
        questionsLength:quizQuestions.length
      })
      //the user refreshes browser, the same question/answers will show up.
      if(this.hasStorage){
        //save randmized quizQuestions and shuffledAnswerOptions objects into localStorage in case user refreshes browser. This will show the same order of questions/answers
        localStorage.setItem('quizQuestionsObj', JSON.stringify(quizQuestions));
        localStorage.setItem('shuffledAnswerOptionsObj', JSON.stringify(shuffledAnswerOptions));
      }
    }
    console.log(localStorage.getItem("quizQuestionsObj"))
  }
  //check to see storage value is set
  isStorageValueSet(storageKey){
    //has storage and quizQuestionsObj is set
    if(this.hasStorage && localStorage.getItem('quizQuestionsObj') !== null){
      //storageKey is set or not null
      if (localStorage.getItem(storageKey) !== null) {
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

  //shuffle the elements of array
  shuffleArray(array){
    var currentIndex = array.length, temporaryValue, randomIndex;

    //while there remain elements to shutffle...
    while(0 !== currentIndex){
      //pick a remaining element
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -=1;

      //and swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  //set chosen answer to state and the change in state will cause react to pass updates to other .js files and show right/wrong answers. 
  setUserAnswer(answer){
    this.setState((prevState)=>({
      // answersCount:{
      //   ...prevState.answersCount,
      //   [answer]:(prevState.answersCount[answer] || 0) + 1
      // },
      chosenAnswer:[...prevState.chosenAnswer,answer]
      //use setState callback to get latest updated info of "state"
    }),()=>this.displayAnswer())
  }

  //increase score if user chose right answer
  setScore(){
    this.setState((prevState)=>({
      score: prevState.score + 1
    }),()=>localStorage.setItem("stateScore", this.state.score))
  }

  setBookmark(){
    //bookmark the question only if questionId is not in it already. To avoid double bookmarking
    if(!this.state.bookmarks.includes(this.state.questionId)){
      //check stateCounter is set. If so, set bookmark to the localstorage quizQuestion object's question id
      if(this.isStorageValueSet("stateCounter")){
        this.setState((prevState)=>({
          bookmarks:[...prevState.bookmarks,this.state.bookmarkMode ? this.newBookmarkQuiz[this.state.counter].id : parseInt(JSON.parse(localStorage.getItem("quizQuestionsObj"))[parseInt(localStorage.getItem("stateCounter"))].id)]
        }),()=>{
          console.log("setbookmark():" + this.state.bookmarks)
          //save bookmark array using stringify because localstorage data is saved as string
          localStorage.setItem("stateBookmarks",JSON.stringify(this.state.bookmarks))
        })
      }else{
        //if stateCounter is not set, then the quiz question is new. So save the question id from quizQuestion object directly or bookmark mode quizQuestion object
        this.setState((prevState)=>({
          bookmarks:[...prevState.bookmarks,this.state.bookmarkMode ? this.newBookmarkQuiz[this.state.counter].id : quizQuestions[this.state.counter].id]
        }),()=>{
          console.log("setbookmark():" + this.state.bookmarks)
          localStorage.setItem("stateBookmarks",JSON.stringify(this.state.bookmarks))
        })
      }
    }
  }

  removeBookmark(){
    //look for the index of the question id and remove it from array using splice
    var bookmarkState = this.state.bookmarks
    var index = bookmarkState.indexOf(this.state.questionId)
    if(index > -1){
      bookmarkState.splice(index, 1);
    }
    this.setState({
      bookmarks:bookmarkState
    },()=>{ 
      console.log("removeBookmark():" + this.state.bookmarks)
      localStorage.setItem("stateBookmarks",JSON.stringify(this.state.bookmarks))
    })
  }

  displayAnswer(){
    //this doesn't method doesn't display the right/wrong answers. Just increase score and bookmark wrongly answered question
    if(this.state.chosenAnswer[this.state.counter] === this.state.answerKey){
      console.log("same answer:")
      this.setScore()
    }else{
      console.log("not the same")
      this.setBookmark()
    }
    localStorage.setItem("stateChosenAnswer",JSON.stringify(this.state.chosenAnswer))
    console.log(this.state.chosenAnswer)
    console.log("answerKey" + this.state.answerKey)
    console.log("score:" + this.state.score)
    console.log("bookmarks:" + this.state.bookmarks)
  }

  setNextQuestion(quizQuestions){
    //increase counter in state to move to next question
    const counter = this.state.counter + 1;
    if(this.hasStorage && localStorage.getItem('quizQuestionsObj') !== null){
      this.setState({
        counter: counter,
        questionId: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].id : parseInt(JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].id),
        question: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].question : JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].question,
        answerOptions: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answers :JSON.parse(localStorage.getItem("shuffledAnswerOptionsObj"))[counter],
        answerKey:this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answerKey : JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].answerKey
        //save newly increased counter to localstorage
      },()=>localStorage.setItem("stateCounter",this.state.counter))
    }else{
      //const questionId = this.state.questionId + 1;
      /*test if current questions are being loaded from the bookmark test mode when bookmarkMode = true
      //if false, it is a fresh start. load the quizQuestions form the quizQuestions.js file.
      */
      this.setState({
        counter: counter,
        questionId: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].id : quizQuestions[counter].id,
        question: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].question : quizQuestions[counter].question,
        answerOptions: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answers : quizQuestions[counter].answers,
        answerKey:this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answerKey : quizQuestions[counter].answerKey
      },()=>localStorage.setItem("stateCounter",this.state.counter))
    }
  }

  setBackQuestion(quizQuestions){
    //decrease counter to go back to previous question
    const counter = this.state.counter - 1;
    if(this.hasStorage && localStorage.getItem('quizQuestionsObj') !== null){
      this.setState({
        counter: counter,
        questionId: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].id : parseInt(JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].id),
        question: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].question : JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].question,
        answerOptions: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answers :JSON.parse(localStorage.getItem("shuffledAnswerOptionsObj"))[counter],
        answerKey:this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answerKey : JSON.parse(localStorage.getItem("quizQuestionsObj"))[counter].answerKey
      },()=>localStorage.setItem("stateCounter",this.state.counter))
    }else{
      this.setState({
        counter: counter,
        questionId: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].id : quizQuestions[counter].id,
        question: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].question : quizQuestions[counter].question,
        answerOptions: this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answers : quizQuestions[counter].answers,
        answerKey:this.state.bookmarkMode ? this.newBookmarkQuiz[counter].answerKey : quizQuestions[counter].answerKey
        //answer:''
      },()=>localStorage.setItem("stateCounter",this.state.counter))
    }
  }

  //an old method from the original code source which sums up all the occurences of an answer
  // getResults(){
  //   const answersCount = this.state.answersCount;
  //   const answersCountKeys = Object.keys(answersCount);
  //   const answersCountValues = answersCountKeys.map(key=>answersCount[key]);
  //   const maxAnswerCount = Math.max.apply(null, answersCountValues);

  //   return answersCountKeys.filter(key=>answersCount[key] === maxAnswerCount);
  // }

  setResults(result){
    // if(result.length ===1){
    //   this.setState({
    //     result: result[0]
    //   })
    // }else{
    //   this.setState({result:'undetermined'})
    // }
    //change this result state will show the result page
    this.setState({
      result: result
    })
    //save these to localStorage only after user completed the whole test. If user refreshes, the result page will load these "previous" data
    if(this.hasStorage){
      localStorage.setItem("bookmarkStore",JSON.stringify(this.state.bookmarks))
      localStorage.setItem("scoreStore", this.state.score)
      localStorage.setItem("lengthStore", this.state.questionsLength)
    }
  }

  //when a user click on any of the answer options, the radio button will call this method.
  handleAnswerSelected(event){
    const {value} = event.currentTarget
    this.setUserAnswer(value);
    // if(this.state.questionId < quizQuestions.length){
    //   setTimeout(()=>this.setNextQuestion(),300);
    // }else{
    //   //calling setResults after 300ms. the delay is a UX decision so user has a moment
    //   //to see the visual feedback indicating that their selection has been made.
    //   setTimeout(()=>this.setResults(this.getResults()), 300);
    // }
  }

  navButtonClick(event){
    //back, next and finish buttons logics
    const {name} = event.currentTarget
    console.log(name)
    if(name === "back"){
      console.log("back is clicked")
      if(this.state.counter+1 > 1){
        this.setBackQuestion(quizQuestions)
      }
    }else if(name ==="next"){
      if(this.state.counter < this.state.questionsLength){
        this.setNextQuestion(quizQuestions)
        //setTimeout(()=>this.setNextQuestion(),300);
      }else{
        //calling setResults after 300ms. the delay is a UX decision so user has a moment
        //to see the visual feedback indicating that their selection has been made.
        // setTimeout(()=>this.setResults(this.getResults()), 300);
      }
    }else{
      //finish button
      // this.setResults(this.getResults());
      //set result state to any value will cause result page to show up 
      this.setResults(1)
      if(this.hasStorage){
        localStorage.setItem("stateResult",1)
      }
      //localStorage.clear()
    }
  }

  //call this to bookmark/unbookmark questions
  bookmarkClick(event){
    if(this.state.bookmarks.includes(this.state.questionId)){
      this.removeBookmark()
    }else{
      this.setBookmark()
    }
  }

  //call this to close modal popup message
  closeModalClick(){
    this.setState({
      isOpen: false
    })
  }

  //call this to pass bookmark array and re-test just bookmarked questions
  testIncorrectClick(userBookmarks){
    //use userBookmarks array to filter for the bookmarked questions only
    this.newBookmarkQuiz = userBookmarks.map((item)=>quizQuestions[item-1])
    this.nBMAnswerOptions = this.newBookmarkQuiz.map((question)=>this.shuffleArray(question.answers))
    this.setState({
      question: this.newBookmarkQuiz[0].question,
      questionId:this.newBookmarkQuiz[0].id,
      answerOptions:this.nBMAnswerOptions[0],
      answerKey:this.newBookmarkQuiz[0].answerKey,
      chosenAnswer:[],
      bookmarks:[],
      bookmarkMode:true,
      questionsLength:this.newBookmarkQuiz.length,
      counter: 0,
      score:0,
      result: ''}, ()=>localStorage.setItem("stateBookmarkMode",this.state.bookmarkMode))

      console.log(this.newBookmarkQuiz) 
  }

  //call this to re-start a completely new test
  restartTestClick(){
    quizQuestions.sort(() => Math.random()-0.5);
    const shuffledAnswerOptions = quizQuestions.map((question)=>this.shuffleArray(question.answers))
    this.setState({
      question:quizQuestions[0].question,
      questionId:quizQuestions[0].id,
      answerOptions:shuffledAnswerOptions[0],
      answerKey:quizQuestions[0].answerKey,
      chosenAnswer:[],
      bookmarks:[],
      bookmarkMode:false,
      questionsLength:quizQuestions.length,
      counter: 0,
      score:0,
      result: ''
    })
    //clear the localstorage
    if(this.hasStorage){
      localStorage.clear()

    }
  }

  //presentation logics section; pass the necessary data to other JS files
  //show the quiz questions
  renderQuiz() {
    return (
      <Quiz
        counter={this.state.counter}
        chosenAnswer={this.state.chosenAnswer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={this.state.questionsLength}
        onAnswerSelected={this.handleAnswerSelected}
        navButtonClick={this.navButtonClick}
        bookmarkClick={this.bookmarkClick}
        answerKey={this.state.answerKey}
        bookmarks={this.state.bookmarks}
      />
    );
  }
  
  //show the result page
  renderResult() {
    return (
      <Result quizResult={parseFloat(this.state.score / this.state.questionsLength * 100).toFixed(1) + "%"}
              quizScore={this.state.score}
              questionTotal={this.state.questionsLength}
              bookmarks={this.state.bookmarks}
              testIncorrectClick={this.testIncorrectClick}
              restartTestClick={this.restartTestClick}
              showModal={this.state.isOpen}
              closeModalClick={this.closeModalClick}
               />
    );
  }

  //determine to show quiz or result page
  render(){
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" /><br />
          <a href='https://apps.apple.com/us/app/id1045754771' target='_blank' rel='noopener noreferrer'><img alt='Download on the App Store' src={appleBadge} height='50' className='appleBadge'/></a>
          <a href='https://play.google.com/store/apps/details?id=com.bubblingiso.uscitizenshiptest' target='_blank' rel='noopener noreferrer'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' height='70'/></a>
          <h2 className="noMargin">US Citizenship Test 2019</h2>
        </div>
          {this.state.result ? this.renderResult() : this.renderQuiz()}
          <GoogleAd
            client="ca-pub-0808441914390455" 
            slot="1695700838" 
            format="auto" 
            wrapperDivStyle={{textAlign: 'center',
                              marginTop: '15px',
                              marginBottom: '20px'}}/>
      </div>
    );
  }
}

export default App;
