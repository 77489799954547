import React from 'react';
import PropTypes from 'prop-types';

function AnswerOption(props){
    var radioGroup = ''
    var radioGroup1Checked = false
    //change radio group name to "radioGroup1" so both the wrong and correct radio buttons can be shown at the same time.
    //radio buttons cannot have two radio buttons selected if they belong to the same group.
    if(props.answerType === props.answerKey && props.chosenAnswer[props.counter] !== undefined){
        radioGroup="radioGroup1" 
        radioGroup1Checked=true
    }else{
        radioGroup="radioGroup"
    }
        
    return (
        <li className="answerOption">
            <input
                type="radio"
                className="radioCustomButton"
                name={radioGroup}
                checked={props.answerType === props.chosenAnswer[props.counter] || radioGroup1Checked}
                id={props.answerType}
                value={props.answerType}
                //disabled if there's a chosen answer
                disabled={props.chosenAnswer[props.counter] !== undefined ? true : false}
                onChange={props.onAnswerSelected}
            />
            {/*show correct answer if radioGroup1Checked is true*/}
            <label className={props.chosenAnswer[props.counter] === props.answerKey || radioGroup1Checked ? "radioCustomLabel" : "radioCustomLabelWrong"} htmlFor={props.answerType}>
                {props.answerContent}
            </label>
        </li>
    )
}


AnswerOption.propTypes ={
    answerType:PropTypes.string.isRequired,
    answerContent:PropTypes.string.isRequired,
    //chosenAnswer:PropTypes.string.isRequired,
    onAnswerSelected:PropTypes.func.isRequired
}
export default AnswerOption;