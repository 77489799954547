import React from 'react';
import PropTypes from 'prop-types';
import Question from '../components/Question';
import AnswerOption from '../components/AnswerOption';
import QuestionCount from '../components/QuestionCount';
import {CSSTransitionGroup} from 'react-transition-group';
import NavButtons from './NavButtons';

function Quiz(props){
    function renderAnswerOptions(key){
        return(
            <AnswerOption 
                counter={props.counter}
                key={key.content}
                answerContent={key.content}
                answerType={key.type}
                chosenAnswer={props.chosenAnswer}
                questionId={props.questionId}
                onAnswerSelected={props.onAnswerSelected}
                answerKey={props.answerKey}
            />
        )
    }
    //hide navbutton back&next if no chosenanswer... show if there's one using style
    const navStyle = props.chosenAnswer[props.counter] === undefined ?  {display: 'none'}: {};
    const backStyle = props.counter === 0 ?  {display: 'none'}: {};
    const nextStyle = props.counter === props.questionTotal-1 ?  {display: 'none'}: {};
    const finishStyle = props.counter === props.questionTotal-1  ?  {}: {display:'none'};
    return(
        <CSSTransitionGroup
            className="container"
            component="div"
            transitionName="fade"
            transitionEnterTimeout={800}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}
        >
            <div key={props.counter}>
                <QuestionCount 
                    counter={props.counter}
                    total={props.questionTotal}
                    bookmarks={props.bookmarks}
                    questionId={props.questionId}
                    bookmarkClick={props.bookmarkClick}
                />
                <Question 
                    content={props.question}
                />
                <ul className="answerOptions">
                    {props.answerOptions.map(renderAnswerOptions)}
                </ul>
            </div>
            <NavButtons 
                navButtonClick={props.navButtonClick} 
                navStyle={navStyle}
                backStyle={backStyle}
                nextStyle={nextStyle}
                finishStyle={finishStyle}
                />
        </CSSTransitionGroup>
    )
}

Quiz.propTypes = {
    //chosenAnswer: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired,
    counter: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTotal: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired
}
export default Quiz;