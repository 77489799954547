import React from 'react';
import PropTypes from 'prop-types';

function QuestionCount(props){
    //check bookmars contains the question, if so, changes the bookmark
    var bookmarkCSSName = props.bookmarks.includes(props.questionId) ? "bookmarked" : "bookmark"
    return(
        <div className="questionCount">
            Question <span>{props.counter+1}</span> of <span>{props.total}</span><button onClick={props.bookmarkClick} className={bookmarkCSSName}/>
        </div>
    );
}

QuestionCount.prototype = {
    counter: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    bookmarks: PropTypes.array.isRequired
};

export default QuestionCount;